import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';




class App extends Component {
  state = {
    apps: []
  }
  
  componentDidMount() {
    fetch('https://deco.maptnh.net/api/v1/apps/')
    .then(res => res.json())
    .then((data) => {
      this.setState({ apps: data.apps })
      console.log(this.state.apps)
    })
    .catch(console.log)
  }

  render() {

    return (
      <div className="container" style={{display: 'grid',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
       <Row xs={1} md={2} className="g-4">
        
        {this.state.apps.map((app) => (

          <Card border="primary" style={{ width: '13rem'}} className="mx-2 my-2">
            <Card.Img variant="top" src={app.icon} />
            <Card.Body>
              <Card.Title>{app.name}</Card.Title>
              <Button href={app.url} variant="secondary">Open app</Button>{' '}
            </Card.Body>
          </Card>

        ))}
        
      </Row>
      <div><span className="small">v0.1.5</span></div>

      </div>

   );
 }



}
export default App;
